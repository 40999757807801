import { Component, OnInit } from '@angular/core';
import { Global } from '../global';

declare var $;

@Component({
  selector: 'app-apertura',
  templateUrl: './apertura.component.html',
  styleUrls: ['./apertura.component.scss']
})
export class AperturaComponent implements OnInit {

  n_files = 1;
  paso = 1;
  apertura = false;
  formulario;
  referencias = [];
  cargando = false;
  camion;
  vulcanizado;
  opcion;
  files = [];


  subiendo_factura_producto = false;
  subiendo_factura_compra = false;
  subiendo_danos = false;
  subiendo_profundidad = false;
  subiendo_foto_vehiculo = false;
  subiendo_factura_reparacion = false;
  subiendo_denuncia = false;
  subiendo_carcasa = false;
  // subiendo_vulcanizado = false;

  constructor(public global: Global) { }

  ngOnInit() {}

  async delete_file(key, i) {

    // await this.global.apiCall("aquaService/deleteFile", "POST", false, { name: this.formulario[key].value[i].new_name });

    this.files.splice(i, 1);

  }

  abrir_documento(file) {

    // window.open(file.url);

  }

  init_formulario() {

    this.formulario = {
      tipo_dano: {
        value: 'Pinchazo',
        error: null,
        paso: 1
      },
      fecha: {
        value: '',
        error: null,
        paso: 1
      },
      email_taller: {
        value: '',
        error: null,
        paso: 1
      },
      matricula: {
        value: '',
        error: null,
        paso: 1
      },
      pol_id: {
        value: '',
        error: null,
        paso: 1
      },
      factura_producto: {
        value: [],
        error: null,
        paso: 2
      },
      factura_compra: {
        value: [],
        error: null,
        paso: 2
      },
      danos: {
        value: [],
        error: null,
        paso: 2
      },
      profundidad: {
        value: [],
        error: null,
        paso: 2
      },
      vehiculo: {
        value: [],
        error: null,
        paso: 2
      },
      reparacion: {
        value: [],
        error: null,
        paso: 2
      },
      // vulcanizado: {
      //   value: [],
      //   error: null,
      //   paso: 2
      // },
      denuncia: {
        value: [],
        error: null,
        paso: 2
      },
      carcasa: {
        value: [],
        error: null,
        paso: 2
      },
      sin_id: {
        value: '',
        error: null,
        paso: 2
      }
    }

    this.opcion = null;
    this.paso = 1;
    this.n_files = 1;
    this.referencias = [];
    this.vulcanizado = 'no';

    setTimeout(() => {
      $("#fecha").datepicker({ 
        dateFormat: 'yy/mm/dd' 
      });
    }, 100);

  }

  async comprobar_campos(evt = null) {

    if (evt) return false;

    let r = true;

    if (this.paso == 1) {

      this.formulario.fecha.value = $("#fecha").val();

      for (let campo in this.formulario) {

        if (this.formulario[campo].paso == 1) {

          if (campo == "matricula") continue;

          this.formulario[campo].error = null;

          if (campo == "pol_id") continue;

          if (this.formulario[campo].value == "") {
            this.formulario[campo].error = "Rellena el campo";
            r = false;
          }

        }

      }

      let fecha = new Date(this.formulario.fecha.value);
      let hoy = new Date();
      let dias = Math.floor((fecha.getTime() - hoy.getTime()) / (1000 * 60 * 60 * 24));
      let dRef;
      if(this.referencias.length != 0){
        let vencimiento = new Date(this.referencias.find(r => r.pol_id == this.formulario.pol_id.value).Vencimiento);
        dRef = Math.floor((fecha.getTime() - vencimiento.getTime()) / (1000 * 60 * 60 * 24));
      }

      if (dias >= 0) {
        this.formulario.fecha.error = "La fecha debe ser inferior a hoy";
        r = false;
        //return r;
      }

      if (dRef >= 0) {
        this.formulario.fecha.error = "La fecha debe ser inferior a la de vencimiento";
        r = false;
        //return r;
      }

      if (this.formulario.matricula.value == "") {
        this.formulario.matricula.error = "Rellena el campo";
        r = false;
      }

      if (this.formulario.matricula.error) r = false;

      if(this.formulario.email_taller.value != ""){
        if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(this.formulario.email_taller.value))) {

          this.formulario.email_taller.error = "Introduzca un email válido";
          r = false;
          //return r;

        }
      } 

    }

    if (this.paso == 2) {

      for (let campo in this.formulario) {

        this.formulario[campo].error = null;

      }

      

      if (this.files.filter(f => f.document == 'factura_producto').length == 0 && this.opcion == 0) {
        this.formulario.factura_producto.error = "Por favor, adjunte una fotografía de la factura del producto";
        r = false;
      }

      if (this.files.filter(f => f.document == 'factura_compra').length == 0 && this.opcion == 1) {
        this.formulario.factura_compra.error = "Por favor, adjunte una fotografía de la factura de compra de los neumáticos";
        r = false;
      }

      if (this.files.filter(f => f.document == 'danos').length == 0) {
        this.formulario.danos.error = "Por favor, adjunte una fotografía de los daños del neumático";
        r = false;
      }

      if (this.files.filter(f => f.document == 'profundidad').length == 0) {
        this.formulario.profundidad.error = "Por favor, adjunte una fotografía de la profundidad de rodadura medida con profundimetro";
        r = false;
      }

      if (this.files.filter(f => f.document == 'vehiculo').length == 0) {
        this.formulario.vehiculo.error = "Por favor, adjunte una fotografía general del vehículo donde se vea la matricula";
        r = false;
      }

      if (this.files.filter(f => f.document == 'reparacion').length == 0) {
        this.formulario.reparacion.error = "Por favor, adjunte una fotografía de la factura de reparación o sustitución de rueda";
        r = false;
      }

      // if (this.formulario.vulcanizado.value.length == 0 && this.vulcanizado == 'si') {
      //   this.formulario.vulcanizado.error = "Por favor, adjunte una fotografía del vulcanizado de la rueda";
      //   r = false;
      // }

      if (this.files.filter(f => f.document == 'denuncia').length == 0 && this.formulario.tipo_dano.value == "Acto vandálico") {
        this.formulario.denuncia.error = "Por favor, adjunte la denuncia del acto vandálico";
        r = false;
      }

      if (this.files.filter(f => f.document == 'carcasa').length == 0 && this.formulario.tipo_dano.value == "Acto vandálico" && this.camion == 1) {
        this.formulario.carcasa.error = "Por favor, adjunte una fotagrafía de la carcasa lateral";
        r = false;
      }

    }

    return r;

  }

  async paso2() {

    let c = await this.comprobar_campos();

    if (!c) return;

    this.cargando = true;

    this.compruebaFicheros();

    this.cargando = false;

    this.paso = 2;

  }

  aperturar() {
    this.init_formulario();
    this.apertura = true;
  }

  async cancelarApertura() {

    // this.cargando = true;

    // if (this.formulario.sin_id.value != '' && this.paso == 2) {

    //   let data = {
    //     sin_id: this.formulario.sin_id.value
    //   };

    //   let result = await this.global.apiCall("neumaticosSoledad/borrar_siniestro", "POST", false, data);

    // }

    // this.cargando = false;

    this.apertura = false;

  }

  checkOpcionAndError(){
    this.formulario.pol_id.error=null;
    this.opcion = this.referencias.find(r => r.pol_id == this.formulario.pol_id.value).Opcion2;
  }

  async checkMatricula(matricula) {

    this.referencias = [];

    this.formulario.matricula.error = null;

    if (this.formulario.matricula.value == "") {

      this.formulario.matricula.error = "Rellena el campo";
      return false;

    }

    this.cargando = true;
    let result = await this.global.apiCall("neumaticosSoledad/checkMatricula", "POST", false, { matricula: matricula });
    this.cargando = false;

    if (!result.status) {

      alert(result.message);
      this.formulario.matricula.error = result.message;
      return false;

    }

    this.referencias = result.data;
    this.formulario.pol_id.value = this.referencias[0].pol_id;
    this.camion = this.referencias[0].Camion;
    this.opcion = this.referencias[0].Opcion2;
  
    return true;

  }

  async subir_documento_factura_producto(fileInput: any) {

    this.subiendo_factura_producto = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: 'factura_producto'
      })

    }

    $("#input_file_factura_producto").val("");

    this.formulario.factura_producto.error = null;

    this.subiendo_factura_producto = false;

  }

  async subir_documento_factura_compra(fileInput: any) {

    this.subiendo_factura_compra = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: 'factura_compra'
      })

    }

    $("#input_file_factura_compra").val("");

    this.formulario.factura_compra.error = null;

    this.subiendo_factura_compra = false;

  }

  async subir_documento_danos(fileInput: any) {

    this.subiendo_danos = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: 'danos'
      })

    }

    $("#input_file_danos").val("");

    this.formulario.danos.error = null;

    this.subiendo_danos = false;

  }

  async subir_documento_profundidad(fileInput: any) {

    this.subiendo_profundidad = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: 'profundidad'
      })

    }

    $("#input_file_profundidad").val("");

    this.formulario.profundidad.error = null;

    this.subiendo_profundidad = false;

  }

  async subir_documento_vehiculo(fileInput: any) {

    this.subiendo_foto_vehiculo = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: 'vehiculo'
      })

    }

    $("#input_file_vehiculo").val("");

    this.formulario.vehiculo.error = null;

    this.subiendo_foto_vehiculo = false;

  }

  async subir_documento_reparacion(fileInput: any) {

    this.subiendo_factura_reparacion = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: 'reparacion'
      })

    }

    $("#input_file_reparacion").val("");

    this.formulario.reparacion.error = null;

    this.subiendo_factura_reparacion = false;

  }


  // async subir_documento_vulcanizado(fileInput: any) {

  //   this.subiendo_vulcanizado = true;

  //   let files = <Array<File>>fileInput.target.files;

  //   for (let file of files) {

  //     let result = await this.uploadFile(file);

  //     if (result.message) {
  //       this.formulario.vulcanizado.error = result.message;
  //       this.subiendo_vulcanizado = false;
  //       $("#input_file_vulcanizado").val("");
  //       return;
  //     }

  //     let url = result.url;
  //     let new_name = result.new_name;

  //     let type = file.type.split('/')[0];

  //     this.formulario.vulcanizado.value.push({
  //       url: url,
  //       type: type,
  //       name: file.name,
  //       new_name: new_name
  //     });

  //   }

  //   $("#input_file_vulcanizado").val("");

  //   this.formulario.vulcanizado.error = null;

  //   this.subiendo_vulcanizado = false;

  //   this.n_files++;

  // }

  async subir_documento_denuncia(fileInput: any) {

    this.subiendo_denuncia = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: 'denuncia'
      })

    }

    $("#input_file_denuncia").val("");

    this.formulario.denuncia.error = null;

    this.subiendo_denuncia = false;

  }

  async subir_documento_carcasa(fileInput: any) {

    this.subiendo_carcasa = true;

    let files = <Array<File>>fileInput.target.files;

    for (let file of files) {

      this.files.push({
        file: file,
        name: file.name,
        document: 'carcasa'
      })

    }

    $("#input_file_carcasa").val("");

    this.formulario.carcasa.error = null;

    this.subiendo_carcasa = false;

  }

  async uploadDocumentos(){

    for(let file of this.files){

      this.cargando = true;

      let result = await this.uploadFile(file.file);

      this.cargando =  false;

      if (result.message) {
        this.formulario[file.document].error = result.message;
        return;
      }

      let url = result.url;
      let new_name = result.new_name;

      let type = file.file.type.split('/')[0];

      this.formulario[file.document].value.push({
        url: url,
        type: type,
        name: file.name,
        new_name: new_name
      });

      this.n_files++;

    }
    
  }

  filterIndex(arr, key, value){
    let aux = [];
    for(let i = 0; i < arr.length; i++) {
      if(arr[key] == value) aux.push(i);
    } 
    return aux;
  }

  deleteFromArrayByCond(arr, key, value) {
    for(let i = arr.length - 1; i >= 0; i--) {
      if(arr[key] == value) {
        arr.splice(i, 1);
      }
    } 
    return arr;
  }

  compruebaFicheros(){

    if(this.opcion == 0){
      if(this.files.filter(f => f.document == 'factura_compra').length != 0){
        this.files = this.deleteFromArrayByCond(this.files, "document", "factura_compra");
      }
      if(this.files.filter(f => f.document == 'denuncia').length != 0){
        this.files = this.deleteFromArrayByCond(this.files, "document", "denuncia");
      }
      if(this.files.filter(f => f.document == 'carcasa').length != 0){
        this.files = this.deleteFromArrayByCond(this.files, "document", "carcasa");
      }
    }
    
    if(this.opcion == 1){
      if(this.camion == 0){
        if(this.formulario.tipo_dano.value == "Acto vandálico"){
          if(this.files.filter(f => f.document == 'factura_producto').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "factura_producto");
          }
          if(this.files.filter(f => f.document == 'carcasa').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "carcasa");
          }
        } else {
          if(this.files.filter(f => f.document == 'factura_producto').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "factura_producto");
          }
          if(this.files.filter(f => f.document == 'denuncia').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "denuncia");
          }
          if(this.files.filter(f => f.document == 'carcasa').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "carcasa");
          }
        }
      }
      if(this.camion == 1){
        if(this.formulario.tipo_dano.value == "Acto vandálico"){
          if(this.files.filter(f => f.document == 'factura_producto').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "factura_producto");
          }
        } else {
          if(this.files.filter(f => f.document == 'factura_producto').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "factura_producto");
          }
          if(this.files.filter(f => f.document == 'denuncia').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "denuncia");
          }
          if(this.files.filter(f => f.document == 'carcasa').length != 0){
            this.files = this.deleteFromArrayByCond(this.files, "document", "carcasa");
          }
        }
      }
    }
  }

  async volver() {

    // this.cargando = true;

    // let data = {
    //   sin_id: this.formulario.sin_id.value
    // };

    // for (let campo in this.formulario) {

    //   this.formulario[campo].error = null;

    // }

    // let result = await this.global.apiCall("neumaticosSoledad/borrar_siniestro", "POST", false, data);
    // this.cargando = false;

    this.paso = 1;

  }

  uploadFile(file) {

    let size = file.size / 1024 / 1014;

    if (size >= 20) {
      return {
        status: false,
        message: "No se pueden subir archivos con un tamaño superior a 20Mb"
      };
    }

    var formData = new FormData();

    formData.append('select_file', file, file.name);
    formData.append('sin_id', this.formulario.sin_id.value);
    formData.append('n_files', this.n_files.toString());

    return $.ajax({
      url: this.global.config.api + 'neumaticosSoledad/uploadFile',
      method: "POST",
      data: formData,
      dataType: 'JSON',
      contentType: false,
      cache: false,
      processData: false
    });

  }

  async cargarFicheros() {

    let c = await this.comprobar_campos();

    if (!c) {

      alert("Comprueba los datos introducidos");
      return;

    }

    let data = {};
    for (let campo in this.formulario) {

      if (this.formulario[campo].paso != 1) continue;

      data[campo] = this.formulario[campo].value;

    }

    let result = await this.global.apiCall("neumaticosSoledad/grabar_siniestro", "POST", false, data);
    this.cargando = false;

    if (!result.status) {
      alert(result.message);
      return;
    }

    this.formulario.sin_id.value = result.data;

    await this.uploadDocumentos();

    this.cargando = true;

    data = {};

    for (let campo in this.formulario) {

      if (this.formulario[campo].paso != 2) continue;

      data[campo] = this.formulario[campo].value;

    }

    result = await this.global.apiCall("neumaticosSoledad/cargarFicheros", "POST", false, data);

    this.cargando = false;

    if (!result.status) {

      alert(result.message);
      return;

    }

    this.paso = 3;

  }

}
