import { Component, OnInit } from '@angular/core';
import { Global } from './global';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public global: Global, private router: Router, private activatedRoute: ActivatedRoute) {



  }

  async ngOnInit() { 

    await this.global.checkVersion();

  }

}
